import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from '../components/molecules/seo'
import Layout from '../components/molecules/layout'
import PostPreview from '../components/atoms/post-preview'

class Changelog extends React.Component {
  render() {
    const title = 'changelog'
    const posts = get(this, 'props.data.allDatoCmsReleaseNote.nodes')
    return (
      <Layout>
        <Seo title={title} />
        <section className="hero is-primary">
          <div className="hero-body">
            <h1 className="page-title">{title}</h1>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-full">
                <main>
                  <PostPreview posts={posts} />
                </main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Changelog

export const pageQuery = graphql`
  query fetchDatoReleaseNotes {
    allDatoCmsReleaseNote(sort: { fields: meta___createdAt, order: DESC }) {
      nodes {
        id
        title
        slug
        meta {
          createdAt(locale: "en")
          fromNow: createdAt(fromNow: true)
        }
      }
    }
  }
`
